import axios from 'axios';
import React, { useEffect, useState } from 'react';


function Home(){
    const [info,setInfo] = useState([]);
    const getData = async() =>{
        try {
            const result = await axios.get('http://localhost:6580/getLdData');
            if(result){
                setInfo(result.data.rows)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(()=>{
        getData();
    },[])
    console.log(info);
   return( <>
    <h4>Home Screen</h4>
   {
    info ?
     info.map((item,i) => {
        return(<div key={i}>{item.ENTRY_DATE}</div>)
    }) : <p></p>
   }
   </>)
}

export default Home;